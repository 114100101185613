// import logo from './logo.svg';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
//import {BrowserRouter, Routes, Route} from 'react-router-dom';
import React from 'react';
import { useRoutes } from "react-router-dom";
import Themeroutes from "./routes/Router";

function App() {

    const routing = useRoutes(Themeroutes);
    return <div className="dark">{routing}</div>;
  };

    // <>
    //   <BrowserRouter>
    //     <Routes>
    //       <Route exact path="/login" element={<Login />} />
    //       <Route exact path="/signup" element={<SignUp />} />
    //       <Route exact path="/forgetpassword" element={<SignUp />} />
    //       <Route exact path="/" redirect to="/dashboard" element={<Dashboard />} />
    //       <Route exact path="/dashboard" element={<Dashboard />} />
    //       <Route exact path="/preferences" element={<Preferences />} />
    //       <Route exact path="/navbar" element={<navbar />} />
    //     </Routes>
    //   </BrowserRouter>
    // </>
  // );

export default App;
