import { lazy } from "react";
import { Navigate } from "react-router-dom";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Login = lazy(() => import("../views/ui/Login"));
const Dashboard = lazy(() => import("../views/Dashboard.js"));
const About = lazy(() => import("../views/About.js"));
const Devices = lazy(() => import("../views/ui/Devices/Devices.js"));
const AddDevice = lazy(() => import("../views/ui/Devices/AddDevice.js"));
const CreateDevice = lazy(() => import("../views/ui/Devices/CreateDevice.js"));
const EditDevice = lazy(() => import("../views/ui/Devices/EditDevice.js"));
const DeviceLog = lazy(() => import("../views/ui/Devices/DeviceLog.js"));
const Gateways = lazy(() => import("../views/ui/Gateways/Gateways.js"));
const AddGateway = lazy(() => import("../views/ui/Gateways/AddGateway.js"));
const EditGateway = lazy(() => import("../views/ui/Gateways/EditGateway.js"));
const Alerts = lazy(() => import("../views/ui/Alerts"));
const Badges = lazy(() => import("../views/ui/Badges"));
const Buttons = lazy(() => import("../views/ui/Buttons"));
const Cards = lazy(() => import("../views/ui/Cards"));
const Grid = lazy(() => import("../views/ui/Grid"));
const Tables = lazy(() => import("../views/ui/Tables"));
const Forms = lazy(() => import("../views/ui/Forms"));
const Breadcrumbs = lazy(() => import("../views/ui/Breadcrumbs"));
const SignUp = lazy(() => import("../views/ui/SignUp"));
const HookMqtt = lazy(() => import("../components/mqtt-hooks/index"));

/*****Routes******/

const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/", element: <Navigate to="/login" /> },
      { path: "/dashboard", exact: true, element: <Dashboard /> },
      { path: "/mqtt", exact: true, element: <HookMqtt /> },
      { path: "/about", exact: true, element: <About /> },
      { path: "/alerts", exact: true, element: <Alerts /> },
      { path: "/devices", exact: true, element: <Devices /> },
      { path: "/devices/add", exact: true, element: <AddDevice /> },
      { path: "/devices/create", exact: true, element: <CreateDevice /> },
      { path: "/devices/edit", exact: true, element: <EditDevice /> },
      { path: "/devices/edit/:id", exact: true, element: <EditDevice /> },
      { path: "/devices/view/:id", exact: true, element: <DeviceLog /> },
      { path: "/gateways", exact: true, element: <Gateways /> },
      { path: "/gateways/add", exact: true, element: <AddGateway /> },
      { path: "/gateways/edit", exact: true, element: <EditGateway /> },
      { path: "/gateways/edit/:id", exact: true, element: <EditGateway /> },
      { path: "/badges", exact: true, element: <Badges /> },
      { path: "/buttons", exact: true, element: <Buttons /> },
      { path: "/cards", exact: true, element: <Cards /> },
      { path: "/grid", exact: true, element: <Grid /> },
      { path: "/table", exact: true, element: <Tables /> },
      { path: "/forms", exact: true, element: <Forms /> },
      { path: "/breadcrumbs", exact: true, element: <Breadcrumbs /> },
    ],
  },
      {path: "/login", exact: true, element:<Login />},
      // {path: "/signup", exact: true, element: <SignUp />}
];

export default ThemeRoutes;
